import { render, staticRenderFns } from "./dashboard-employer.html?vue&type=template&id=20ca2529&scoped=true&"
import script from "./dashboard-employer.js?vue&type=script&lang=js&"
export * from "./dashboard-employer.js?vue&type=script&lang=js&"
import style0 from "./dashboard-employer.scss?vue&type=style&index=0&id=20ca2529&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ca2529",
  null
  
)

export default component.exports